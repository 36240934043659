<template>
    <meta-data-vue :Auhtor="'Martin Tiedemann'" :Description="'Står I også og overvejer hvilken type reklameskærm I skal vælge for bedst muligt at fremme jeres budskab? Så se med her, hvor vi hjælper jer videre i jagten til at finde den helt rigtige løsning'" :ImgUrl="require('@/assets/images/screens/martin.png')" :Title="'Få jeres egen reklameskærm'" />
    <div class="top-spacer"></div>
    <div class="page-wrapper">
        <TextWithSideImage 
            BackgroundColor="#f1f1f1" 
            MinHeight="20" 
            Headline="Få jeres egen <span>reklameskærm!</span>" 
            Content="<p>Vi udbyder alle typer af skærme. LCD og LED skærme til udendørs og indendørs brug.</p>
<p>Vores spidskompetence er LED-skærme, og nedenfor har vi listet priserne for de tre typiske skærmstørrelser, når det kommer til udendørs LED skærme. </p>
<p>Er I på udkig efter en anden skærmstørrelse eller løsning, må I endelig række ud til os på kontakt@reklameskaerm.dk.</p>"
            :ImageUrl="require('@/assets/images/screens/martin.webp')"
            ImageAlt="Martin Tiedemann"
            Position="left"
        />
        <TextWithSideImage 
            BackgroundColor="white" 
            MinHeight="20" 
            Headline="<span>LED-</span>skærm <span>200x100 cm. </span> 3.9P" 
            Content="<p>Udendørs LED. inkl. computer, controller og lyscensor</p>
                    <span>Lite LED: <b>26.500 kr.</b></span><br>
                    <span>- Diode ledere i kobber - 5.000 nits - 7 års levetid*</span>
                    <br><br>
                    <span>Prime LED: <b>31.800 kr.</b></span><br>
                    <span>- Diode ledere i guld - 6.500 nits - COT - 10 års levetid*</span>
                    <br>
                    <p>Tilkøb</p>
                    <ul>
                    <li>Levering &amp; montering (+650 kr./time)</li>
                    <li>Pylon inkl. fundament (+32.000 kr.)</li>
                    </ul>"
            :ImageUrl="require('@/assets/images/screens/small.jpg')"
            ImageAlt="Lille LED"
            Position="left"
        />
        <TextWithSideImage 
            BackgroundColor="#f1f1f1" 
            MinHeight="20"  
            Headline="<span>LED-</span>skærm <span>300x150 cm. </span> 3.9P"
            Content="<p>Udendørs LED. inkl. computer, controller og lyscensor</p>
                    <span>Lite LED: <b>51.400 kr.</b></span><br>
                    <span>- Diode ledere i kobber - 5.000 nits - 7 års levetid*</span>
                    <br><br>
                    <span>Prime LED: <b>61.700 kr.</b></span><br>
                    <span>- Diode ledere i guld - 6.500 nits - COT - 10 års levetid*</span>
                    <br>
                    <p>Tilkøb</p>
                    <ul>
                    <li>Levering &amp; montering (+650 kr./time)</li>
                    <li>Pylon inkl. fundament (+47.000 kr.)</li>
                    </ul>"
            :ImageUrl="require('@/assets/images/screens/medium.jpg')"
            ImageAlt="Mellem LED"
            Position="left"
        />
        <TextWithSideImage 
            BackgroundColor="white" 
            MinHeight="20"  
            Headline="<span>LED-</span>skærm <span>400x200 cm. </span> 3.9P"
            Content="<p>Udendørs LED. inkl. computer, controller og lyscensor</p>
                    <span>Lite LED: <b>86.100 kr.</b></span><br>
                    <span>- Diode ledere i kobber - 5.000 nits - 7 års levetid*</span>
                    <br><br>
                    <span>Prime LED: <b>103.300 kr.</b></span><br>
                    <span>- Diode ledere i guld - 6.500 nits - COT - 10 års levetid*</span>
                    <br>
                    <p>Tilkøb</p>
                    <ul>
                    <li>Levering &amp; montering (+650 kr./time)</li>
                    <li>Pylon inkl. fundament (+77.000 kr.)</li>
                    </ul>"
            :ImageUrl="require('@/assets/images/screens/big.jpg')"
            ImageAlt="Stor LED"
            Position="left"
        />
        <Textblock BackgroundColor="#f1f1f1"  Content="
            <h2>Skærmstørrelse</h2>
            <p>Ovenstående skærme er blot eksempler på typiske skærmstørrelser, men derimod ikke mål man skal holde sig til. Såfremt I har en pris-forespørgsel på en specifik skærmstørrelse, vil I blot skulle kontake os via mail: kontakt@reklameskaerm.dk.</p>
            <h2 style='margin-top: 3rem;'>Forbehold</h2>
            <ul>
                <li>Strøm skal være ført til lokationen indenfor 2 meters radius. 230V - 16A - 3-faset.</li>
                <li>Byggetilladelse anbefales at blive søgt via Byg & Miljø (www.bygogmiljoe.dk). Vi leverer nødvendige tegninger hertil uden beregning.</li>
                <li>Ophæng til facaden indgår i de viste priser. Montering af skærm på facade er med forbehold for at facaden er af beton og nogenlunde plan.</li>
                <li>Ved opførelse af pylon vil lokationen skulle tage højde for afstand til skel, nuværende kabler i jorden, og ligeledes skal jorden være nogenlunde plan.</li>
                <li>Ovenstående skærme kan købes, lejes, leases og finansieres ved kreditgodkendelse heraf.</li>
                <li>Grafikstyring til skærmen er nødvendigt for at skifte budskaberne herpå fra enhver lokation, hvor du måtte befinde dig. Abonnement starter fra 75 kr./md. & oprettelse til 495 kr.</li>
                <li>Internet-kabel skal føres ud til skærmens lokation. Såfremt det ikke er muligt, vil det være muligt at tilkøbe et 4G-datakort til skærmen.</li>
            </ul>
            <br>
            <h2>Uddybning af specifikationer</h2>
            <ul>
                <li><b>3.9P</b> er størrelsen i millimeter på dioden. Jo mindre diode, jo finere er opløsningen. Ved en diode på 3.9P vil skærmens opløsning stå klart helt ned til 3,9 meter fra skærmen. En diode på 3.9 mm. er meget lavt for udendørs LED skærme, og såfremt der ikke er behov for denne fine opløsning, vil der være penge at spare ved at øge størrelsen på dioden.</li>
                <li><b>COT</b> er en film belægning til skærmen, som sikrer skærmens drift i høje og lave temperaturer, styrker hæftelsen af dioderne, og forøger dermed levetiden for skærmen betragteligt.</li>
                <li><b>Diode ledere</b> er metallet som fører strømmen mellem dioderne. Dette er typisk kobber, men det er også muligt at bruge guld, hvilket øger mængden af nits som en diode kan levere, og herudover sikrer guld bedre imod korossion.</li>
                <li><b>Nits</b> er specifikationen for lysstyrke. Jo højere nits, jo bedre kan man aflæse budskaberne på skærmen i det udendørs miljø. Her er det nemlig solens lysstyrke, som skærmen er oppe imod. På en højlys dag, vil en skærm skulle kunne lyse med mere end 4.000 nits for at kunne blive tydet.</li>
                <li><b>*Levetid</b> er et estimat for antallet af år der vil gå, før en udskiftning anbefales.</li>
                <li><b>Lyscensoren</b> gør at skærmen automatisk justerer op og ned i lysstyrke / nits, for herved at sikre den kan lyse budskaberne op på en højlys dag. Ligeledes justerer den lysstyrken ned når det ermørkt, og herved sparer skærmen på strømmen og blænder ikke nogen.</li>
                <li><b>Pylon inkl. fundament</b> udgør en stålramme / skilt hvori skærmen monteres, således skærmen kan placeres på et jordstykke. Fundamentet er en stålkonstruktion som hæftes i jorden, og hvorpå pylonen monteres.</li>
            </ul>
        " />
    </div>
</template>

<script>
import Textblock from '../components/layouts/Textblock.vue';
// @ is an alias to /src
import TextWithSideImage from '../components/layouts/TextWithSideImage.vue';
import MetaDataVue from '../components/shared/MetaData.vue';

export default {
    name: 'GetYourOwnScreen',
    components: {
        TextWithSideImage,
        Textblock,
        MetaDataVue
    },
    data() {
        return {
            caseID: ''
        }
    },
    mounted() {
        this.caseID = this.$route.params.caseID;
    }
}
</script>

<style scoped>



@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .page-wrapper {
      width: 100% !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .top-spacer {
      height: 50px !important;
    }

    .page-wrapper h2 {
      padding-left: 20px;
    }
}

.top-spacer {
  width: 100%;
  height: 80px;
}



</style>
